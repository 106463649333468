<template>
  <div>
    <v-btn color="primary" @click="redirectToAuth()">Conectar Banco do Brasil</v-btn>
  </div>
</template>

<script>
export default {
  
  data : () => ({
    host : 'https://oauth.sandbox.bb.com.br/ui/authorize',
    //client_id : 9036,
    //client_id : 'd27b87790bffab101367e17d50050056b9d1a5bb',
    client_id : 'eyJpZCI6IjY4NSIsImNvZGlnb1B1YmxpY2Fkb3IiOjAsImNvZGlnb1NvZnR3YXJlIjoxNzUxNywic2VxdWVuY2lhbEluc3RhbGFjYW8iOjF9',
    response_type : 'code',
    redirect_uri : 'http://app.erp-cdt.com.br/bb-callback',
    scope : 'pix.read', // ???
    state : '132644'
    
    // redirect_uri : 'https://hookb.in/G9jr99WZKLTWGGeQY3Qz'
    //https://oauth.sandbox.bb.com.br/ui/authorize?response_type=code&redirect_uri=https://callback.meusite.com.br&client_id=eyJpaCI6ImdzMmFkMzztYTNQMy00N2EwLS3sImNvZGInb1JJYmxpY2Fkb3IiOjAsImNvZGlnb1NvZnR3YXJlIjoxNiwic2VxdWVuY2lhbEluc3RhbGFjYW8iOjF9
    //&scope=consultas-financeiras.extratos&state=132644 
  }),
  
  mounted() {
    if (this.$route.query.code) {
      this.postAuth(this.$route.query.code)
    }
  },

  methods : {
    // redirectToAuth() {
    //   let url = this.host + 
    //   '?response_type=' + this.response_type + 
    //   '&redirect_uri=' + this.redirect_uri +
    //   '&client_id=' + this.client_id +
    //   '&scope=' + this.scope +
    //   '&state=' + this.state  

    //   console.log(url)
    //   window.location.href = url;
    // },

    postAuth(code) {
      
      console.log(code)

      let params = {
        authorization_code : code
      }

      this.$http.$post('mercado-pago/auth', params)
        .then(response => {

        })
    }
  }
};
</script>

<style>
</style>